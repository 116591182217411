import * as React from 'react';
import { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Unstable_Grid2';
import './voteopen.css'; // 引入樣式表
import { CssBaseline, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const DynamicNumberDisplay = () => {
  const [number, setNumber] = useState(10);
  const [vote1, setVote1] = useState(0);
  const [vote2, setVote2] = useState(0);
  const [vote3, setVote3] = useState(0);
  const profileURL2 = '/avatar-2.jpg';
  const profileURL3 = '/avatar-3.jpg';
  const profileURL6 = '/avatar-6.jpg';
  const emblem2 = '/emblem01.png';
  const emblem3 = '/emblem02.png';
  const emblem6 = '/emblem03.png';
  
  function formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  /*function formatNumberWithDecimals(number) {
    return number.toFixed(2);
  }*/

  useEffect(() => {
    // 更新每秒數字
    const interval = setInterval(() => {
      setNumber(preNumber => {
        preNumber = (preNumber===10) ? 0 : preNumber+1;
        if (preNumber===0) {
          fetch("https://docs.google.com/spreadsheets/u/0/d/1hckxlr0AinHANIZ66gaT8bwHaTSOFQjr7iQasXMR1-c/gviz/tq?tqx=out:json&headers=0&tq=select+B,C+where+A%3D%27%E8%B3%B4%E5%A3%AB%E8%91%86%27+OR+A+%3D%27%E7%8E%8B%E9%96%94%E7%94%9F%27+OR+A+%3D%27%E5%BC%B5%E5%85%B6%E7%A5%BF%27&gid=851306623", {
            method: "GET",
            headers: {
              'Content-Type': 'application/json',
            },
          })
            .then((response) => {
              return response.text();
            })
            .then((data) => {
              let jdata = JSON.parse( data.substring(data.indexOf('{'), data.lastIndexOf('}')+1) );
              //console.log("data", jdata.table);
              setVote1(jdata.table.rows[0].c[0].f);
              setVote2(jdata.table.rows[1].c[0].f);
              setVote3(jdata.table.rows[2].c[0].f);
            })
            .catch((error) => console.log(error));

        }
        return preNumber;
      });
    }, 1000);
    // 清理函數在component卸載時執行
    return () => {
      clearInterval(interval);
    };
  }, []); // 空的依賴陣列意味著此side effect只在component掛載和卸載時運行

  return (
    /*<div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '200vh' }}>
      <CssBaseline />
      <TableContainer component={Paper} style={{ background: 'url("./voteopen.jpg") center/cover', width: '100%' }}>
        <Table>
          <TableBody>
            <TableRow sx={{height:"5vh"}}>
              <TableCell>Row 2, Column 1</TableCell>
              <TableCell>Row 2, Column 2</TableCell>
            </TableRow>
            <TableRow sx={{height:"50vh"}}>
              <TableCell>Row 2, Column 1</TableCell>
              <TableCell>Row 2, Column 2</TableCell>
            </TableRow>
            <TableRow sx={{height:"45vh"}}>
              <TableCell>Row 2, Column 1</TableCell>
              <TableCell>Row 2, Column 2</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>  */  
      <Grid container sx={{background: "url('./voteopen.jpg') center/cover",backgroundSize: '100% 100%', width: '187vh'}}>
        <Grid item sm={12} sx={{height:"5vh"}}>
          <Box sx={{width:"100%",display:"flex",justifyContent:'right',color:"#FFFFFF"}}>
            <span>更新倒數：{10-number}</span>
          </Box>
        </Grid>
        <Grid item sm={12} sx={{height:"50vh", alignItems:'center'}}>
          <Box sx={{height:"15vh"}}>&nbsp;</Box>
          <Box sx={{textAlign:"right",justifyContent:"right",width:"100%",height:"100%"}}>
            <span style={{fontSize:"25vh",fontWeight:"bold"}}>{formatNumberWithCommas(vote1)}<span style={{fontSize:"50px"}}>票</span></span>
          </Box>
        </Grid>
        <Grid item sm={6} sx={{height:"45vh"}}>
          <Box sx={{height:"15vh"}}>&nbsp;</Box>
          <Box sx={{textAlign:"right",justifyContent:"right",width:"100%",height:"100%"}}>
            <span style={{fontSize:"20vh",fontWeight:"bold"}}>{formatNumberWithCommas(vote2)}<span style={{fontSize:"50px"}}>票</span></span>
          </Box>
        </Grid>
        <Grid item sm={6} sx={{height:"45vh"}}>
          <Box sx={{height:"15vh"}}>&nbsp;</Box>
          <Box sx={{textAlign:"right",justifyContent:"right",width:"100%",height:"100%"}}>
            <span style={{fontSize:"20vh",fontWeight:"bold"}}>{formatNumberWithCommas(vote3)}<span style={{fontSize:"50px"}}>票</span></span>
          </Box>
        </Grid>
      </Grid>
    //<div style={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //</div>
    /*<Grid container>
      <Grid item sm={12} sx={{height:"60%"}}>
        <Box className="candidate0" id="candidate0">
          <Box></Box>
          <Box></Box>
          <Box className="vote-info">
            <span>更新倒數：{10-number}</span>
          </Box>
        </Box>
      </Grid>
      <Grid item sm={12}>
          <Box sx={{display:"flex","justify-content":"space-between","align-items":"center",height:"50vh",padding:"0 20px",color:"white",
                  background: "linear-gradient(to bottom right, #5858f6, #000078)"}}>
            
            <Box>
              <Avatar src={profileURL2} sx={{width: '40vh', height: '40vh'}}/>
            </Box>
            <Box sx={{width:"100%"}}>
              <Box sx={{display:"flex",justifyContent:"left",alignItems:"center"}}>
                <div style={{width: '30px'}}>&nbsp;</div>
                <Avatar src={emblem2} sx={{width: '20vh', height: '20vh'}}/>
                <Typography fontSize="100px">賴士葆②</Typography>
                <div style={{width: '10px'}}>&nbsp;</div>
              
                <Box sx={{textAlign:"right",justifyContent:"right",width:"40%"}}>
                  <span style={{fontSize:"100px",fontWeight:"bold"}}>{formatNumberWithCommas(vote1*10000)}<span style={{fontSize:"50px"}}>票</span></span>
                  
                </Box>
              </Box>
            </Box>
          </Box>
      </Grid>
      <Grid item sm={6}>
          <Box sx={{display:"flex","justify-content":"space-between","align-items":"center",height:"45vh",width:"100%",padding:"0 0px",color:"white",
                  background: "linear-gradient(to right, #01b801, #005300)"}}>
            
            <div style={{width:'30px'}}>&nbsp;</div>
            <Box>
              <Avatar src={profileURL3} sx={{width: '25vh', height: '25vh'}}/>
            </Box>
            <Box sx={{width:"100%"}}>
              <Box sx={{display:"flex",  justifyContent:"right",  alignItems:"center"}}>
                <Avatar src={emblem3} sx={{width: '10vh', height: '10vh'}}/>
                <Typography fontSize="80px">王閔生③</Typography>
                <div style={{width: '30px'}}>&nbsp;</div>
              </Box>
              <Box sx={{display:"flex",justifyContent:"right", alignItems:"center"}}>
                <Box sx={{textAlign:"right",justifyContent:"right"}}>
                  <span className="votes">{formatNumberWithCommas(vote2*100)}<span style={{fontSize:"50px"}}>票</span></span>
                  
                </Box>
                <div style={{width: '30px'}}>&nbsp;</div>
              </Box>
            </Box>
          </Box>
      </Grid>
      <Grid item sm={6}>
          <Box sx={{display:"flex","justify-content":"space-between","align-items":"center",height:"45vh",width:"100%",padding:"0 0px",color:"white",
                  background: "linear-gradient(to right, #fac6c6, #ff5c5c)"}}>
            
            <div style={{width:'30px'}}>&nbsp;</div>
            <Box>
              <Avatar src={profileURL6} sx={{width: '25vh', height: '25vh'}}/>
            </Box>
            <Box sx={{width:"100%"}}>
              <Box sx={{display:"flex",  justifyContent:"right",  alignItems:"center"}}>
                <Avatar src={emblem6} sx={{width: '12vh', height: '12vh'}}/>
                <Typography fontSize="80px">張其祿⑥</Typography>
                <div style={{width: '30px'}}>&nbsp;</div>
              </Box>
              <Box sx={{display:"flex",justifyContent:"right", alignItems:"center"}}>
                <Box sx={{textAlign:"right",justifyContent:"right"}}>
                  <span className="votes">{formatNumberWithCommas(vote3*100)}<span style={{fontSize:"50px"}}>票</span></span>
                  
                </Box>
                <div style={{width: '30px'}}>&nbsp;</div>
              </Box>
            </Box>
          </Box>
      </Grid>
    </Grid> */
  );
};

export default DynamicNumberDisplay;